import { getCmsUrl } from 'helpers';
import i18n from '../i18n';

const fetchInitialContent = async () => {
  return await fetch(`${getCmsUrl()}/api/content/initial?lang=${i18n.language}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res);
};

const fetchPageData = async (path, params = {}) => {
  if (path == null || path === '/400' || path === '/404') {
    return null;
  }

  const queryString = new URLSearchParams(params).toString();
  const url = `${getCmsUrl()}/api/content/page?path=${path}&lang=${i18n.language}`;

  const finalUrl = queryString ? `${url}&${queryString}` : url;

  return await fetch(finalUrl, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  })
    .then(res => res)
    .catch(err => {
      // This then() and catch() are not executed.
      // How to handle 404?
      console.error('Error fetching page data:', err);
      return null;
    });
};

const sendFeedback = async (data) => {
  const response = await fetch(`${getCmsUrl()}/api/feedback?lang=${i18n.language}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(data),
  });

  const responseData = await response.json();

  return {
    status: response.status,
    data: responseData,
  };
};

const checkAvailability = async (products, orderId) => {
  const response = await fetch(`${getCmsUrl()}/api/order/product?lang=${i18n.language}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify({
      'products': products,
      'orderId': orderId
    }),
  })

  const responseData = await response.json();

  return {
    status: response.status,
    data: responseData,
  };
}
const addClientToOrder = async (orderId, client, locale) => {
  const response = await fetch(`${getCmsUrl()}/api/order/client?lang=${i18n.language}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify({
      'client': { ...client, locale },
      'orderId': orderId,
    }),
  })

  const responseData = await response.json();

  return {
    status: response.status,
    data: responseData,
  };
}

const addShippingDataToOrder = async ({orderId, shippingMethod, details}) => {
  const response = await fetch(`${getCmsUrl()}/api/order/shipping?lang=${i18n.language}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({
      'order_id': orderId,
      'shipping_method_id': shippingMethod?.id || null,
      // 'shipping_method': shippingMethod?.code || null,
      'shipping_location': shippingMethod?.pickupPoint || null,
      // 'shipping_price': shippingMethod?.price || null, // Why are we sending price here?
      ...details
    }),
  })

  const responseData = await response.json();

  return {
    status: response.status,
    data: responseData,
  };
}

const addPaymentMethod = async ({orderId, paymentMethod}) => {
  const response = await fetch(`${getCmsUrl()}/api/order/payment?lang=${i18n.language}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({
      'paymentMethod': paymentMethod,
      'orderId': orderId
    }),
  })

  const responseData = await response.json();

  return {
    status: response.status,
    data: responseData,
  };
}

const checkOrder = async ({ token }) => {
  try {
    const response = await fetch(`${getCmsUrl()}/api/order/check_payment?lang=${i18n.language}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        'token': token,
      }),
    })

    const responseData = await response.json();

    return {
      status: response.status,
      data: responseData,
    };
  } catch (error) {
    console.error('Error fetching order data:', error);
    return {
      status: 400,
      data: null,
    };
  }
}

const fetchConsent = async (language) => {
  const res = await fetch(`${getCmsUrl()}/api/content/consent?lang=${language}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  });
  return await res.json();
}

const subscribeToBackInStockNotification = async (data) => {
  const response = await fetch(`${getCmsUrl()}/api/stock-notification?lang=${i18n.language}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(data),
  });

  const responseData = await response.json();

  return {
    status: response.status,
    data: responseData,
  };
}

export {
  fetchConsent,
  fetchPageData,
  fetchInitialContent,
  sendFeedback,
  checkAvailability,
  addClientToOrder,
  addShippingDataToOrder,
  addPaymentMethod,
  checkOrder,
  subscribeToBackInStockNotification
};
